.summary-box {
  background: #FFFFFF;
  border: solid 1px #eaeaea;
  padding: 15px;
  &.bordered {
    border-color: $input-border-color;
  }
  .alert {
    margin: 5px 0 0 0;
  }
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-tabs {
  &.summary {
    .nav-link {
      &.active {
        background-color: #FFFFFF;
        border-bottom-color: #FFFFFF;
      }
    }
  }
}

.tab-pane {
  > .summary-box {
    border-top: none;
  }
}