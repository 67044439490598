/* Fill the void */

$font-family-base: 'Roboto Condensed', sans-serif;
$line-height-base: 1.8;

$secondary-font: 'Work Sans', sans-serif;

$btn-focus-box-shadow: none !important;
$input-focus-box-shadow: none !important;
$btn-box-shadow: none !important;

$headings-color: #4f4f4f;
$headings-font-weight: 400;

$btn-border-width: 2px;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$custom-select-border-radius: 0;

$body-bg: #F2F2F2;
$body-color: #464545;
$font-size-base: 0.875rem;

$primary: #127C4C;
$dark: #2A2A2A;

$link-color: $primary;

$pagination-active-bg: $primary;

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:wght@300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:wght@400,700&display=swap');
@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/scss/font-awesome';
@import 'components';